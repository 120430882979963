'use client'
import { memo } from 'react'
import dynamic from 'next/dynamic'
import styled from 'styled-components'
import { mq } from '@/styles'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })

const VideoContent = styled.div<any>`
  display: flex;
  flex-direction: ${({ $isContent }) => $isContent ? 'unset' : 'row'};
  grid-column:  ${({ $isContent }) => $isContent ? 'unset' : '1 / span 6'};
  grid-row:  ${({ $isContent }) => $isContent ? 'unset' : '1 / span 3'};
  height: ${({ $isContent }) => $isContent ? '100%' : '100vh'};
  height: ${({ $isContent }) => $isContent ? '100%' : '100dvh'};
  overflow: hidden;
  width:${({ $isContent }) => $isContent ? '100%' : '100vw'};

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
  }


  div {
    &[data-vimeo-initialized='true'] {
      display: inline-block;
      height: ${({ $isContent }) => $isContent ? '100%' : '100vh !important'};
      height: ${({ $isContent }) => $isContent ? '100%' : '100dvh !important'};
      margin: 0;
      padding: 0;
      position: relative;
      width:${({ $isContent }) => $isContent ? '100%' : '100vw !important'};
      z-index: 1;
    }
  }

  iframe {
    aspect-ratio: 16 / 9;
    left: 0;
    position: absolute;
    top: 0;
  }

  .player-portrait {
    opacity: 1;

    ${mq.greaterThan('desktop-xs')} {
      display: ${({ $isTouchDevice }) => !$isTouchDevice ? 'none' : 'block'};
      opacity: ${({ $isTouchDevice }) => !$isTouchDevice ? 0 : 1};
    }

    @media (min-aspect-ratio: 9/16) {
      display: block;
      opacity: 1;
    }

    iframe {
      aspect-ratio: 9 / 16;
      height: auto !important;
      width:${({ $isContent }) => $isContent ? '100% !important' : '100vw !important'};

      @media (min-aspect-ratio: 16/9) {
        height: auto !important;
        width:${({ $isContent }) => $isContent ? '100% !important' : '100vw !important'};
      }
    }
  }

  .player-landscape {
    display: block;
    opacity:1;

    @media (min-aspect-ratio: 16/9) {
      display: block;
      opacity: 1;
    }

    ${mq.greaterThan('desktop-xs')} {
      display: block;
      opacity: 1;
    }

    iframe {
      aspect-ratio: 16 / 9;
      height: auto !important;
      width: 100% !important;

      /* @media (min-aspect-ratio: 16/9) {
        height: auto !important;
        width: 100% !important;
      } */
      @media (max-aspect-ratio: 16/9) {
        height: 100% !important;
        width: auto !important;
      }
    }
  }
`

interface iContentVideo {
  data: {
    platform: string,
    long_video: string,
    short_video: string
  },
  isContent: boolean
}

export const ContentVideo = memo(({ data, isContent }:iContentVideo) => {
  const { short_video } = data
  const url = `https://www.vimeo.com/${short_video}`

  const onLandscapeBufferEnd = () => {
    console.log('* video landscape is ready to play')
    // setVideoLandscapeReady(true)
  }
  return (
    <VideoContent className={isContent ? 'content' : ''} $isContent={isContent}>
      <ReactPlayer key='p-land' className='player-landscape' url={url} width={'100%'} height={'100%'} controls={false} volume={0} muted={true} loop={true} title={''} playsinline={true} playing={true} onBufferEnd={onLandscapeBufferEnd} />
    </VideoContent>
  )
})
