'use client'
import styled from 'styled-components'
import { Dropdown } from '@/components'
import { mq, vw } from '@/styles'
import { useParams } from 'next/navigation'
import { motion, AnimatePresence } from 'framer-motion'
import { homePagevariants } from '@/components'

const Navbar = styled(motion.nav)`
  align-items: end;
  display: flex;
  flex-direction: column;
  display: flex;
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  padding: ${vw(25, 'mobile')};
  z-index: 100;
  align-items: end;

  ${mq.greaterThan('tablet')} {
    align-items: flex-start;
    flex-direction: row;
    grid-column: 1 / span 12;
    height: ${vw(133, 'desktop')};
    justify-content: flex-end;
    padding: ${vw(35, 'desktop')} ${vw(40, 'desktop')};
  }

  ${mq.greaterThan('container')} {
    height: 133px;
    padding: 35px 40px;
  }
`

const arrayOptions = [
  { value: 'ES', path:'/es-ES' },
  // {value: 'CA', path:'/ca-ES'},
  { value: 'EN', path:'/' },
  // {value: 'RU', path:'/ru-RU'}
]

export const Nav = () => {
  const { locale } = useParams()
  return (
    <AnimatePresence>
      <Navbar variants={homePagevariants} initial='initial' animate={'animate'} exit='exit'>
        <Dropdown className='detail-dropdown' trigger={( typeof locale === 'string' ? locale.split('-')[0] : 'en').toUpperCase()} options={arrayOptions} direction={'down'} />
      </Navbar>
    </AnimatePresence>
  )
}
