'use client'
import styled from 'styled-components'
import { vw, mq, getP50 } from '../../styles'
import { LinkStyled } from '../LinkStyled'
import { motion, AnimatePresence } from 'framer-motion'
import { ContentText } from '..'
import { useDeviceType } from '@/hooks'
import { useParams } from 'next/navigation'

export interface IMain {
  setShowSliderHomes: (boolean) => void,
  setShowSliderContracts: (boolean) => void,
  setShowOverlay: (boolean) => void,
  text: any
}
export const homePagevariants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: {
      duration: .8,
      type: 'spring',
      ease: 'linear',
      delay: 1.5,
      stiffness: 80,
      damping: 20,
      mass: 1
    }
  },
  exit: {
    opacity: 0,
    duration: .1,
  }
}


const MainWrapper = styled(motion.main)<any>`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 6;
  grid-row: 1 / span 3;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 91;

  a, button {
    cursor: pointer;
  }

  > div, h1, p {
    display: flex;
    flex-direction:column;
    justify-content: center;

    ${mq.greaterThan('tablet')} {
      flex-direction:row;
    }
  }

  h1, p {
    ${getP50()}
    color: var(--color-white);
    margin-bottom: ${vw(30, 'mobile')};
    text-align: center;
    width: 98%;

    ${mq.lessThan('nexus7')} {
      margin-bottom:${vw(30, 'mobile')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(30, 'desktop')};
      width: ${vw(900, 'desktop')};
    }

    ${mq.greaterThan('container')} {
      margin-bottom: 30px;
      width: 900px;
    }
  }

  ${mq.lessThan('tablet')} {
    margin-bottom:${vw(60, 'mobile')};
  }

  ${mq.greaterThan('nexus7')} {
    margin-bottom:${vw(60, 'mobile')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
    justify-content: center;
    margin-bottom: 0;
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    flex-direction: row;
    justify-content: center;
    margin-bottom: 0;

    a {
      margin: 0 10px;
    }

    a:first-of-type {
      margin-bottom: 0 !important;
    }

  }

  a {
    height: ${vw(69, 'mobile')};
    width: ${vw(364, 'mobile')};

    ${mq.greaterThan('tablet')} {
      height: ${vw(87, 'desktop')};
      width: ${vw(308, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 87px;
      width: 308px;
    }

    @media screen and (min-device-width: 320px) and (max-device-width: 767px) and (orientation: landscape) {
      height: 87px;
      width: 260px;

      > div:last-of-type {
        font-size: 28px;
      }
    }
  }

  a {
    &:first-of-type {
      ${mq.lessThan('nexus7')} {
        margin-bottom: ${vw(15, 'mobile')};
      }

      ${mq.greaterThan('nexus7')} {
        margin-bottom: ${vw(15, 'mobile')};
      }

      ${mq.greaterThan('tablet')} {
        margin-bottom: unset;
        margin-left: auto;
        margin-right: 20px;
      }
    }

    &:last-of-type {
      ${mq.greaterThan('tablet')} {
        margin-left: 20px;
        margin-right: auto;
      }
    }
  }
`

export const Main = ({ setShowSliderHomes, setShowSliderContracts, setShowOverlay, text}: IMain) => {
  const { locale } = useParams()
  const { isTouchDevice } = useDeviceType()
  return (
    <AnimatePresence>
      <MainWrapper variants={homePagevariants} initial='initial' animate={'animate'} exit='exit'>
        <ContentText data={text}/>
        <div>
          <LinkStyled href={typeof locale === 'string' ? `/${locale}/homes` : '/homes'} onMouseOver={(e) => {
            e.stopPropagation()
            !isTouchDevice && setShowSliderHomes(true)
          }}  onMouseOut={(e) => {
            e.stopPropagation()
            setShowSliderHomes(false)
          }} onClick={()=> {
            setShowOverlay(true)
          }}outline>HOMES</LinkStyled>
          <LinkStyled href={typeof locale === 'string' ? `/${locale}/contract` : '/contract'} outline onMouseOver={(e) => {
            e.stopPropagation()
            !isTouchDevice && setShowSliderContracts(true)
          }} onMouseOut={(e) => {
            e.stopPropagation()
            setShowSliderContracts(false)
          }}
          onClick={()=> {
            setShowOverlay(true)
          }}
          >CONTRACT</LinkStyled>
        </div>
      </MainWrapper>
    </AnimatePresence>

  )
}
