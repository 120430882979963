'use client'
import styled from 'styled-components'
import { mq, vw,createUnderline, getP50 } from '@/styles'


const StyledFooter = styled.footer`
    align-self: stretch;
    color: var(--color-darkgreen);
    display: flex;
    flex-direction: column;
    grid-column: 1 / span 6;
    justify-content: flex-end;
    margin:${vw(30, 'mobile')} ${vw(10, 'mobile')} ${vw(50, 'mobile')};

  ${mq.lessThan('tablet')} {
    margin:${vw(30, 'mobile')} ${vw(10, 'mobile')} ${vw(50, 'mobile')};
  }

  ${mq.greaterThan('nexus7')} {
    margin:${vw(30, 'mobile')} ${vw(10, 'mobile')} ${vw(50, 'mobile')};
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
    margin: ${vw(30, 'desktop')} ${vw(100, 'desktop')} ${vw(50, 'desktop')};
    width: ${vw(960, 'desktop')};
  }

  ${mq.greaterThan('container')} {
    margin: 30px 100px 50px;
    width: 960px;
  }

  p {
    ${getP50()}
  }

  a, button {
    ${createUnderline({ color: 'darkgreen', height:2, reverse: true })}
  }
`

export const Footer = () => {
  return (
    <StyledFooter>
      <p>Get in touch at <a>hi@luzio.studio</a> or schedule a call <a>here.</a> We also invite you to explore our <a>instagram</a> and our <a>contract portfolio.</a></p>
    </StyledFooter>
  )
}
