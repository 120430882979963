export const randomNumberInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const templates = {
  'template1': [
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'fullImage' },
    { type: 'slider', value: 'doubleSmallImages' },
    { type: 'slider', value: 'doubleSmallImages' },
    { type: 'slider', value: 'widerFullImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template2': [
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template3': [
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'bigHeightLeftImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template4': [
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template5': [
    { type: 'slider', value: 'fullImage'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template6': [
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'doubleSmallImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'doubleSmallImagesVariant'},
    { type: 'slider', value: 'doubleSmallImagesVariant'},
    { type: 'slider', value: 'bigHeightLeftImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template7': [
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template8': [
    { type: 'slider', value: 'fullImage' },
    { type: 'slider', value: 'smallHeightRightImage' },
    { type: 'slider', value: 'widerFullImage' },
    { type: 'slider', value: 'fullImage' },
    { type: 'slider', value: 'doubleHeightImages' },
    { type: 'slider', value: 'doubleHeightImages' },
    { type: 'slider', value: 'doubleSmallImages' },
    { type: 'slider', value: 'doubleSmallImages' },
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template9': [
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template10': [
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'fullImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template11': [
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template12': [
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template13': [
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template14': [
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template15': [
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'smallHeightLeftImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ],
  'template16': [
    { type: 'slider', value: 'hugeImage'},
    { type: 'slider', value: 'smallHeightRightImage'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'widerFullImage'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'slider', value: 'doubleHeightImages'},
    { type: 'text', value: 'paragraph', position: 0 },
    { type: 'text', value: 'paragraph', position: 1 },
    { type: 'text', value: 'paragraph', position: 2 },
  ]
}


export const shuffleArray = (array, cap = 30) => {
  const mutatedArray = [...array]
  for (let i = mutatedArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [mutatedArray[i], mutatedArray[j]] = [mutatedArray[j], mutatedArray[i]]
  }
  return mutatedArray.slice(0,cap)
}

export function shuffle(array:any[]) {
  for (let i = array.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i
    const j = Math.floor(Math.random() * (i + 1));
    // Swap elements at i and j
    [array[i], array[j]] = [array[j], array[i]]
  }
  return array
}
