'use client'
import { memo } from 'react'
import { Link } from '@/components'
import { useParams } from 'next/navigation'
export interface IButtonBack {
  setShowOverlay: (boolean) => void,
}
export const ButtonBack = memo(({setShowOverlay}: IButtonBack) => {
  const { locale } = useParams()
  
  return (
    <Link href={typeof locale === 'string' ? `/${locale}` : '/'} className='back' onClick={()=> { setShowOverlay(true) }}>
      <img src='/images/svg/i--chevron.svg' alt='Icon arrow back' width='24' height='24'/>
    </Link>
  )
})
