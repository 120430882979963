'use client'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { mq, vw, getP18_1B, getP15_1B, getP40 } from '../../styles'

interface IButton {
  bgType?: 'transparent',
  children: ReactNode,
  circle?: boolean,
  className?: string,
  disabled?: boolean,
  id?: string,
  name?: string
  onClick?: any,
  outline?: boolean,
  type?: 'button' | 'submit',
  uppercase?: boolean,
  noHoverEffect?: boolean,
  initial?: any,
  variants?: any,
  exit?: any,
  animate?: any,
  custom?: any
}

export const handleBgTypeHover = (bgType, theme) => {
  switch (bgType) {
    case 'transparent':
      return theme?.colors?.transparent
    default:
      return theme?.colors?.white
  }
}
//test commit2
const ButtonStyled = styled(motion.button)<any>`
  ${getP15_1B()}
  align-items: center;
  background-color: ${({ $bgType, $outline }) => $outline ? 'transparent' : `var(--color-${$bgType})`};
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  height: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : `${vw(50, 'mobile')}`};
  justify-content: center;
  letter-spacing: 0;
  margin: 0 auto;
  padding: ${({ $circle }) => $circle ? 0 : `${vw(17, 'mobile')} ${vw(30, 'mobile')}`};
  position: relative;
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'initial'};
  width: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : 'unset'};

  ${mq.greaterThan('tablet')} {
    ${getP18_1B()}
    height: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : `${vw(60, 'desktop')}`};
    padding: ${({ $circle }: any) => $circle ? 0 : `${vw(19, 'desktop')} ${vw(43, 'desktop')}`};
    width: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : 'unset'};
  }

${mq.greaterThan('desktop')} {
    height: ${({ $circle }: any) => $circle ? '40px' : '60px'};
    padding: ${({ $circle }: any) => $circle ? 0 : '19px 43px'};
    width: ${({ $circle }: any) => $circle ? '40px' : 'unset'};
  }

  &[type='submit'] {
    ${getP15_1B()}
  }

  &,
  &:before {
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    transition: 300ms background-color ${({ ease }) => ease }, 300ms border ${({ ease }) => ease }, 300ms color ${({ ease }) => ease }, 300ms filter ${({ ease }) => ease }, 300ms opacity ${({ ease }) => ease };

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(50, 'desktop')}`};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '50px'};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;

    &:before {
      content: none;
      display: none;
    }
  }

  > div:last-of-type {
    ${getP40()}
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 2;
    white-space: nowrap;

    img {
      padding: ${vw(10, 'mobile')};

      ${mq.greaterThan('tablet')} {
        padding: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        padding: 10px;
      }
    }
  }

  .button__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${({ $bgType, $outline }) => $outline ? 'transparent' : `var(--color-${$bgType})`};
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    border: ${({ $outline }) => $outline ? '1px solid var(--color-white)' : 'none'};

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(50, 'desktop')}`};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '50px'};
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, .2);
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    border: none;

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(50, 'desktop')}`};
    }

    ${mq.greaterThan('desktop')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '50px'};
    }
  }
`

export const Button = memo<IButton>(({ bgType = 'darkcreme', children, circle = false, disabled = false, className = '', id, name = 'Button', onClick = null, outline = false, type = 'button', uppercase = false, noHoverEffect = false, initial, variants, exit, animate, custom}) => {
  return (
    <ButtonStyled {...(id && { id })} className={className} onClick={onClick} type={type} $bgType={bgType} $circle={circle} disabled={disabled} $uppercase={uppercase} name={name} $outline={outline} $noHoverEffect={noHoverEffect} {...(initial && { initial })} {...(variants && { variants })} {...(exit && { exit })} {...(animate && { animate })} {...(custom && { custom })} >
      <div className='button__bg'></div>
      <div>{children}</div>
    </ButtonStyled>
  )
})
