'use client'
import { memo, useState } from 'react'
import styled from 'styled-components'
import { parserMedia } from '@/utils'
import { mq, vw } from '@/styles'
import { Figure } from '../../media'

interface IContentImage {
  data: any,
  contentIndex: number
}

export const Fig = styled(Figure)<any>`
  background-color: rgba(0, 0, 0, .03);
  height: auto;
  width: ${({ $size }) => $size === 'small' ? `${vw(69 * 4, 'mobile')}` : $size === 'medium' ? `${vw(69 * 6, 'mobile')}` : '100%'};

  ${mq.greaterThan('tablet')} {
    width: ${({ $size }) => $size === 'small' ? `${vw(120 * 4, 'desktop')}` : $size === 'medium' ? `${vw(120 * 8, 'desktop')}` : '100%'};
  }

  ${mq.greaterThan('desktop')} {
    width: ${({ $size }) => $size === 'small' ? '480px' : $size === 'medium' ? '960px' : '100%'};
  }

  > span {
    position: relative !important;
    height: 100% !important;
    width: 100%;
  }

  img {
    opacity: 1;
    height: 100% !important;
    position: relative !important;
    transition: 225ms opacity ease-out;
    width: 100% !important;
  }

`

const getSizeByLayout = (layout) => {
  switch(layout) {
    case 'doubleHeightImages':
    case 'doubleSmallImages':
    case 'doubleSmallImagesVariant':
    case 'doubleHeightImages':
      return '40 vw'
    case 'smallHeightLeftImage':
    case 'smallHeightRightImage':
    case 'bigHeightLeftImage':
      return '50vw'
    case 'widerFullImage':
    case 'fullImage':
    case 'hugeImage':
    default:
      return '70vw'
  }
}

export const ContentImage = memo(({ data, contentIndex }: IContentImage) => {
  const { caption, layout } = data
  const [isLoaded, setIsLoaded] = useState(false)

  const onLoad = () => {
    setIsLoaded(true)
  }

  return (
    <Fig $isLoaded={isLoaded} media={parserMedia(data)} figCaption={caption} lazy={contentIndex > 4} quality={60} sizes={getSizeByLayout(layout)} onLoad={() => onLoad()} />
  )
})
