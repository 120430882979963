// All JSONLD availables
import { jsonLdHomepage } from './jsonld/homepage'


const jsonLdByType = {
  'home': (data) => jsonLdHomepage(data),
}

type TypeGetJsonld = {
  type: keyof typeof jsonLdByType,
  data?: {
    [key:string]: any
  },
  locale?: 'en' | 'es' | 'en-GB' | 'es-ES' | 'en-EN' | 'EN' | 'ES'
}

export const getJsonld = ({ type, data = null}:TypeGetJsonld) => jsonLdByType[type](data)
