'use client'
import { memo } from 'react'
import { TextContent } from '../contentStyles'

type ContentTypeText = {
  col: { from: number, expand: number},
  row: { from: number, expand: number},
  align?: {
    v: 'top' | 'center' | 'bottom',
    h: 'left' | 'center' | 'right'
  },
  color?: string,
  titleText?: string,
  type: 'text'
}

interface IContentText {
  data: ContentTypeText
}

export const ContentText = memo(({ data }:IContentText) => {
  const { titleText } = data
  return (
    <TextContent className={'text-wrapper'} dangerouslySetInnerHTML={{ __html: titleText }} />
  )
})
