'use client'
import { memo, ReactNode } from 'react'
import styled from 'styled-components'
import { mq, vw, getP18_1B, getP15_1B, getP40 } from '../styles'
import { Link } from './Link'

interface ILinkStyled {
  href: string,
  target?: string,
  ariaDisabled?: boolean,
  rel?: string,
  locale?: string,
  title?: string,
  bgType?: 'transparent',
  children: ReactNode,
  circle?: boolean,
  className?: string,
  id?: string,
  outline?: boolean,
  uppercase?: boolean,
  onMouseOver?:any
  onMouseOut?:any
  onClick?:any
}

//test commit2
// const Linkstyled = styled(Link)<any>`
const Linkstyled = styled(Link)<any>`
${getP15_1B()}
  align-items: center;
  background-color: ${({ $bgType, $outline }) => $outline ? 'transparent' : `var(--color-${$bgType})`};
  color: var(--color-white);
  cursor: pointer;
  display: flex;
  justify-content: center;
  letter-spacing: 0;
  margin: 0 auto;
  position: relative;
  text-transform: ${({ $uppercase }) => $uppercase ? 'uppercase' : 'initial'};

  ${mq.lessThan('tablet')} {
    height: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : `${vw(50, 'mobile')}`};
    padding: ${({ $circle }) => $circle ? 0 : `${vw(17, 'mobile')} ${vw(30, 'mobile')}`};
    width: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : 'unset'};
  }

  ${mq.greaterThan('nexus7')} {
    height: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : `${vw(50, 'mobile')}`};
    padding: ${({ $circle }) => $circle ? 0 : `${vw(17, 'mobile')} ${vw(30, 'mobile')}`};
    width: ${({ $circle }) => $circle ? `${vw(38, 'mobile')}` : 'unset'};
  }

  ${mq.greaterThan('tablet')} {
    ${getP18_1B()}
    height: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : `${vw(60, 'desktop')}`};
    padding: ${({ $circle }: any) => $circle ? 0 : `${vw(19, 'desktop')} ${vw(43, 'desktop')}`};
    width: ${({ $circle }: any) => $circle ? `${vw(40, 'desktop')}` : 'unset'};
  }

  ${mq.greaterThan('container')} {
    height: ${({ $circle }: any) => $circle ? '40px' : '60px'};
    padding: ${({ $circle }: any) => $circle ? 0 : '19px 43px'};
    width: ${({ $circle }: any) => $circle ? '40px' : 'unset'};
  }

  &[type='submit'] {
    ${getP15_1B()}
  }

  &,
  &:before {
    ${mq.lessThan('tablet')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
      transition: 300ms background-color ${({ ease }) => ease }, 300ms border ${({ ease }) => ease }, 300ms color ${({ ease }) => ease }, 300ms filter ${({ ease }) => ease }, 300ms opacity ${({ ease }) => ease };
    }

    ${mq.greaterThan('nexus7')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(50, 'desktop')}`};
    }

    ${mq.greaterThan('container')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '50px'};
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
    pointer-events: none;

    &:before {
      content: none;
      display: none;
    }
  }

  > div:last-of-type {
    ${getP40()}
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
    z-index: 2;
    white-space: nowrap;

    img {
      ${mq.lessThan('nexus7')} {
       padding: ${vw(10, 'mobile')};
      }

      ${mq.greaterThan('nexus7')} {
        padding: ${vw(10, 'mobile')};
      }

      ${mq.greaterThan('tablet')} {
        padding: ${vw(10, 'desktop')};
      }

      ${mq.greaterThan('container')} {
        padding: 10px;
      }
    }
  }

  .link__bg {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: ${({ $bgType, $outline }) => $outline ? 'transparent' : `var(--color-${$bgType})`};
    border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    border: ${({ $outline }) => $outline ? '1px solid var(--color-white)' : 'none'};

    ${mq.lessThan('tablet')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    }

    ${mq.greaterThan('nexus7')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(50, 'desktop')}`};
    }

    ${mq.greaterThan('container')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '50px'};
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, .2);
    border: none;

    ${mq.lessThan('tablet')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    }

    ${mq.greaterThan('nexus7')} {
      border-radius: ${({ $circle }) => $circle ? '50%' : `${vw(50, 'mobile')}`};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : `${vw(50, 'desktop')}`};
    }

    ${mq.greaterThan('container')} {
      border-radius: ${({ $circle }: any) => $circle ? '50%' : '50px'};
    }
  }
  > div {
    pointer-events:none;
  }
`

export const LinkStyled = memo<ILinkStyled>(({ href = '/', target = '', ariaDisabled, rel, locale, title, bgType = 'darkcreme', children, circle = false, className = '', id, outline = false, uppercase = false, onMouseOver = null, onMouseOut = null, onClick = null}) => {
  return (
    <Linkstyled href={href} target={target} {...(id && { id })}  {...onMouseOver && { onMouseOver }} {...onMouseOut && { onMouseOut }} {...onClick && { onClick }} className={className} $bgType={bgType} $circle={circle} $uppercase={uppercase} $outline={outline} {...rel && { rel }} {...ariaDisabled && { 'aria-disabled': ariaDisabled }} {...title && { title }} {...locale && { locale }}>
      <div className='link__bg'></div>
      <div>{children}</div>
    </Linkstyled>
  )
})
