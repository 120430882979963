export const parserMedia = (data) => {
  if(data?.type === 'svg') {
    return ({
      type: data.type,
      alt: data.alt,
      default: {
        src: data.url
      }
    })
  }

  if(!data?.default) return data
  const media = {
    type: data.type,
    alt: data.alt,
    default: {
      src: data?.default?.src,
      size: {
        height: data?.default?.size?.height,
        width: data?.default?.size?.width,
      }
    }
  }

  return media
}
