/* eslint-disable quotes */

export const jsonLdHomepage = (data) => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "",
    "name": "Luzio Studio",
    "url": "https://luzio.studio",
    "isPartOf": {
      "@type": "WebSite"
    },
    "publisher": {
      "@type": "CreativeWork",
      ...(data.seo && { "name": `${data.seo.title}` }),
      ...(data.seo && { "description": `${data.seo.description}`}),
      "publisher": "Luzio Studio",
      "url": "https://luzio.studio",
      "keywords": "Design, interior design, projects, proyectos de interiorismo, interiorismo y diseño, diseño de interiores, estudio de interiorismo, tendencias, interior design trends, design trends, interiorismo, maximalismo, estilo maximalista,diseño maximalista, retail, arquitectura, bespoke projects, diseño de interiores Barcelona, reformas, decoración de interiores, decoración",
      "genre": "http://vocab.getty.edu/aat/300215390",
      "sameAs": [
        "https://www.instagram.com/luzioconceptstore/",
        "https://www.facebook.com/luzioconceptstore"
      ],
      "image": "https://luzio.studio/images/og_cover.jpg",  // required CHECK
      // "logo": "https://luzio.studio/images/logo.png"
    }
  }

  return JSON.stringify(schema)
}
