'use client'
import { useEffect, useState, memo, useMemo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { ContentVideo, Figure } from '@/components'
import { mq, getTransition } from '@/styles'
import { randomNumberInRange } from '@/utils'

export interface IHeader {
  showSliderHomes: boolean,
  showSliderContracts: boolean,
  contracts: {media:[]},
  homes:{media:[]}
}
const HeaderStyled = styled.header`
  /* background: rgba(0, 0, 0, .4); */
  bottom: 0;
  display: flex;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 0;

  figure {
    height: 100vh;
    opacity: 1;
    width: 100vw;
  }

  img {
    object-fit: cover;
  }

  &:after {
    display: block;
    content: '';
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
  }
`

const Ul = styled.ul<any>`
  display: grid;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 700ms ease-in-out;
  user-select: none;
  width: 100vw;
  z-index: auto;

  &.homes {
    opacity: ${({ $showSliderHomes }) => $showSliderHomes ? 1 : 0};
  }

  &.contracts {
    opacity: ${({ $showSliderContracts }) => $showSliderContracts ? 1 : 0};
  }
`

const Li = styled(motion.li)<any>`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  height: 100vh;
  width: 100vw;

  &:after {
    background: rgba(0, 0, 0, .4);
    display: block;
    content: '';
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
  }

  ${mq.greaterThan('tablet')} {
    grid-column: 1 / span 12;
  }
`

const transition = getTransition(.7)
const variants = {
  initial: {
    opacity: 0,
    transition
  },
  animate: {
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0,
    transition
  }
}

const imagesSizes = '(min-width: 768px) 80vw, 80vw'
const getItems = (dataArray, type = 'homes', activeIdx) => dataArray.map((item, idx) => {
  const { medium } = item
  if(!medium) {
    console.log(`error with ${item}, ${activeIdx}`)
    return <></>
  }
  return (
    <Li key={`${type}-slide-${idx}`} className={`id-${idx}`} variants={variants} initial='initial' animate={activeIdx === idx ? 'animate' : 'initial'} exit='exit' >
      {medium.type === 'image' ?
        <Figure media={medium} lazy={idx > 1} quality={60} sizes={imagesSizes} />
        :
        <ContentVideo data={medium} isContent={false} />
      }
    </Li>
  )
})

let arraysIdsCreated = false
let interval
export const Header = memo(({ showSliderHomes, showSliderContracts, contracts, homes }:IHeader) => {
  const [activeHomesIndex, setActiveHomesIndex] = useState(0)
  const [activeContractsIndex, setActiveContractsIndex] = useState(0)
  const Homes = useMemo(() => getItems(homes?.media, 'homes', activeHomesIndex), [homes, activeHomesIndex])
  const Contracts = useMemo(() => getItems(contracts?.media, 'contracts', activeContractsIndex), [contracts, activeContractsIndex])

  useEffect(() => {
    if (homes?.media?.length > 0 && showSliderHomes) {
      interval = setInterval(() => {
        setActiveHomesIndex(prevIndex => {
          let incomingIndex = randomNumberInRange(0, homes?.media?.length -1)
          do {
            incomingIndex = randomNumberInRange(0, homes?.media?.length -1)
          } while (prevIndex === incomingIndex)
          return incomingIndex
        })
      }, 3000)

      return () => {
        clearInterval(interval)
      }
    }
    else {
      if(interval) {
        return () => {
          clearInterval(interval)
          interval = null
        }
      }
    }

  }, [showSliderHomes, setActiveHomesIndex, homes?.media?.length])


  useEffect(() => {
    if(contracts?.media?.length > 0 && showSliderContracts) {
      interval = setInterval(() => {
        setActiveContractsIndex(prevIndex => {
          let incomingIndex = randomNumberInRange(0, contracts?.media?.length -1)
          do {
            incomingIndex = randomNumberInRange(0, contracts?.media?.length -1)
          } while (prevIndex === incomingIndex)
          return incomingIndex
        })
      }, 3000)

      return () => {
        clearInterval(interval)
        interval = null
      }
    }
    else {
      if(interval) {
        return () => {
          clearInterval(interval)
          interval = null
        }
      }
    }

  }, [showSliderContracts, setActiveContractsIndex, contracts?.media?.length])

  useEffect(() => {
    if(!arraysIdsCreated) {
      setActiveHomesIndex(0)
      setActiveContractsIndex(0)
      arraysIdsCreated = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <HeaderStyled >
      <Ul $showSliderHomes={showSliderHomes} $showSliderContracts={showSliderContracts} className={'homes'}>
        <AnimatePresence>
          {Homes}
        </AnimatePresence>
      </Ul>
      <Ul $showSliderContracts={showSliderContracts} $showSliderHomes={showSliderHomes} className={'contracts'} >
        <AnimatePresence>
          {Contracts}
        </AnimatePresence>
      </Ul>
    </HeaderStyled>
  )
})
