export const NEXT_PUBLIC_LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const LOCALE = process.env.NEXT_PUBLIC_LOCALE
export const HOSTNAME = process.env.NEXT_PUBLIC_PROJECT_HOSTNAME
export const DEV = process.env.NEXT_PUBLIC_VERCEL_ENV === 'development'
export const STAGING = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
export const PRO = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'


export * from './slugify'
export * from './handyMethods'
export * from './copy_clipboard'
export * from './pdf_downloader'
export * from './currency'
export * from './cookies'
export * from './analytics'
export * from './newsletter'
export * from './parserMedia'
export * from './cart'
export * from './parseLocale'
export * from './inputAutoComplete'
export * from './inputValidations'
export * from './inputPatterns'
export * from './random'
export * from './jsonld'
export * from './luzio'
