'use client'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { parserMedia, randomNumberInRange } from '@/utils'
import { Fig } from './ContentImage'
import { mq, vw } from '@/styles'
import { useMount } from '@/hooks'
import dynamic from 'next/dynamic'

const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false })
const DynamicSliderFlickity = dynamic(() => import('../../media/SliderFlickity').then(mod => mod.SliderFlickity), { ssr: false })

interface IContentSlider {
  data: any,
  contentIndex: number
}

const getSlides = (images) => {
  const slides = []
  for(const [idx, data] of images.media.entries()) {
    const { medium, caption} = data
    const {type, platform, short_video, long_video, alt } = medium
    const url = `https://www.${platform}.com/${platform === 'youtube' ? 'watch?v=' : ''}${long_video ?? short_video}`
    const keyForComponent = `${idx}-${typeof alt === 'string' ? alt.split(' ').join('') : ''}`
    slides.push(
      <Slide className={type} key={keyForComponent}>
        {type === 'image' ?
          <>
            <Fig media={parserMedia(medium)} lazy={idx > 1} quality={60} />
            <figcaption >{caption}</figcaption>
          </>
          :
          <>
            <ReactPlayer key='p-land' className='player-landscape' url={url}  controls={false} volume={0} muted={true} loop={true} title={''} playsinline={true} playing={true}/>
            <figcaption>{caption}</figcaption>
          </>

        }
      </Slide>
    )
  }
  return slides
}

export const ContentSlider = memo(({ data }:IContentSlider) => {
  const Slides = useMemo(() => getSlides(data), [data])
  const isMounted = useMount()
  if(!Slides) return null

  return isMounted && (
    <Div>
      <DynamicSliderFlickity className={'slider-'} dynamicSlider={true} options={{
        autoPlay: 15000,
        pauseAutoPlayOnHover: false,
        draggable: false,
        initialIndex: randomNumberInRange(0, 8),
        pageDots: false,
        wrapAround: true
      }}>
        {Slides}
      </DynamicSliderFlickity>
    </Div>
  )
})


const Div = styled.div<any>`
  height: 100%;
  width: 100%;
  pointer-events: none;

  .flickity-enabled {
    position: relative;
  }

  .flickity-slider, .flickity-cell {
    display: grid;
    transform: translateX(0) !important;
  }

  .flickity-cell {
    grid-column: 1/ span 1;
    grid-row: 1/ span 1;
    opacity: 0;
    transform: translate(0, 0) !important;
    transition: 600ms opacity linear;

    figcaption {
      opacity: 0 !important;
      transition: 500ms opacity ease-in-out;
    }

    &.is-selected {
      opacity: 1;

      figcaption {
        opacity: 1 !important;
        /* transition: 500ms opacity ease-in-out; */
        transition-delay: 300ms;
      }
    }
  }
`
const Slide = styled.div<any>`
  width: 100%;

  figcaption {
    color: var(--color-darkgreen);
    font-family: var(--font-work-sans);
    font-style: normal;
    margin-top: ${vw(3, 'mobile')};
    text-align: left;
    text-transform: uppercase;

    ${mq.greaterThan('tablet')} {
      margin-top: ${vw(10, 'desktop')};
    }
  }

  iframe {
    aspect-ratio: 16 / 9;
    left: 0;
    position: absolute;
    top: 0;
  }


  .player-landscape {
    iframe {
      aspect-ratio: 16 / 9;
      height: auto !important;
      width: 100% !important;

      @media (max-aspect-ratio: 16/9) {
        height: 100% !important;
        width: auto !important;
      }
    }
  }
`
