import styled, { css } from 'styled-components'
import { mq, vw } from '@/styles'
import { motion } from 'framer-motion'
const template = {
  '1': css`
    .paragraph:not(:last-child) {
      order: 1;
    }

    .paragraph ~ .paragraph:not(:last-child) {
      order: 5;
    }

    .paragraph {
      order:11;
      width: 100%;
    }

    .doubleHeightImages:first-of-type {
      order: 2;
    }

    .doubleHeightImages:nth-of-type(2) {
      order: 3;
    }

    .widerFullImage:nth-of-type(3) {
      order: 4;
    }

    .smallHeightLeftImage:nth-of-type(4) {
      order: 6;
    }

    .fullImage:nth-of-type(5) {
      order: 7;
    }

    .doubleSmallImages:nth-of-type(6) {
      order: 8;
    }

    .doubleSmallImages:nth-of-type(7) {
      order: 9;
    }

    .widerFullImage:nth-of-type(8) {
      order: 10;
    }

  `,
  '2': css`
    .paragraph:not(:last-child) {
      order: 1;
    }

    .paragraph ~ .paragraph:not(:last-child) {
      order: 5;
    }

    .paragraph {
      order:11;
      width: 100%;
    }

    .widerFullImage:first-of-type {
      order: 2;
    }

    .smallHeightRightImage:nth-of-type(2) {
      order: 3;
    }

    .widerFullImage:nth-of-type(3) {
      order: 4;
    }

    .doubleSmallImages:nth-of-type(4) {
      order: 6;
    }

    .doubleSmallImages:nth-of-type(5) {
      order: 7;
    }

    .widerFullImage:nth-of-type(6) {
      order: 8;
    }

    .doubleHeightImages:nth-of-type(7) {
      order: 9;
    }

    .doubleHeightImages:nth-of-type(8) {
      order: 10;
    }

 `,
  '3': css`
  .paragraph:not(:last-child) {
    order: 1;
  }

  .paragraph ~ .paragraph:not(:last-child) {
    order: 6;
  }

  .paragraph {
    order:10;
    width: 100%;
  }

  .doubleSmallImages:first-of-type {
    order: 2;
  }

  .doubleSmallImages:nth-of-type(2) {
    order: 3;
  }

  .bigHeightLeftImage:nth-of-type(3) {
    order: 4;
  }

  .widerFullImage:nth-of-type(4) {
    order: 5;
  }

  .doubleSmallImages:nth-of-type(5) {
    order: 7;
  }

  .doubleSmallImages:nth-of-type(6) {
    order: 8;
  }

  .hugeImage:nth-of-type(7) {
    order: 9;
  }

  `,
  '4': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 7;
   }

   .paragraph {
     order:10;
     width: 100%;
   }

   .doubleHeightImages:first-of-type {
     order: 2;
   }

   .doubleHeightImages:nth-of-type(2) {
     order: 3;
   }

   .smallHeightRightImage:nth-of-type(3) {
     order: 4;
   }

   .hugeImage:nth-of-type(4) {
     order: 5;
   }

   .smallHeightLeftImage:nth-of-type(5) {
     order: 6;
   }

   .hugeImage:nth-of-type(6) {
     order: 8;
   }

   .widerFullImage:nth-of-type(7) {
     order: 9;
   }

   `,
  '5': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 7;
   }

   .paragraph {
     order:11;
     width: 100%;
   }

   .fullImage:first-of-type {
     order: 2;
   }

   .smallHeightRightImage:nth-of-type(2) {
     order: 3;
   }

   .doubleHeightImages:nth-of-type(3) {
     order: 4;
   }

   .doubleHeightImages:nth-of-type(4) {
     order: 5;
   }

   .widerFullImage:nth-of-type(5) {
     order: 6;
   }

   .doubleSmallImages:nth-of-type(6) {
     order: 8;
   }

   .doubleSmallImages:nth-of-type(7) {
     order: 9;
   }

   .hugeImage:nth-of-type(8) {
     order: 10;
   }

   `,
  '6': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 5;
   }

   .paragraph {
    order:10;
    width: 100%;
   }

   .doubleSmallImages:first-of-type {
    order: 2;
   }

   .doubleSmallImages:nth-of-type(2) {
     order: 3;
   }

   .widerFullImage:nth-of-type(3) {
     order: 4;
   }

   .hugeImage:nth-of-type(4) {
     order: 6;
   }

   .doubleSmallImagesVariant:nth-of-type(5) {
     order: 8;
   }

   .doubleSmallImagesVariant:nth-of-type(6) {
     order: 9;
   }

   .bigHeightLeftImage:nth-of-type(7) {
     order: 10;
   }

   `,
  '7': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 5;
   }

   .paragraph {
     order:10;
     width: 100%;
   }

   .hugeImage:first-of-type {
     order: 2;
   }

   .doubleHeightImages:nth-of-type(2) {
     order: 3;
   }

   .doubleHeightImages:nth-of-type(3) {
     order: 4;
   }

   .smallHeightLeftImage:nth-of-type(4) {
     order: 6;
   }

   .widerFullImage:nth-of-type(5) {
     order: 8;
   }

   .smallHeightRightImage:nth-of-type(6) {
     order: 9;
   }

   .hugeImage:nth-of-type(7) {
     order: 10;
   }

   `,
  '8': css`
    .paragraph:not(:last-child) {
      order: 1;
    }

    .paragraph ~ .paragraph:not(:last-child) {
      order: 6;
    }

    .paragraph {
      order:11;
      width: 100%;
    }

    .fullImage:first-of-type {
      order: 2;
    }

    .smallHeightRightImage:nth-of-type(2) {
      order: 3;
    }

    .widerFullImage:nth-of-type(3) {
      order: 4;
    }

    .fullImage:nth-of-type(4) {
      order: 5;
    }

    .doubleHeightImages:nth-of-type(5) {
      order: 7;
    }

    .doubleHeightImages:nth-of-type(6) {
      order: 8;
    }

    .doubleSmallImages:nth-of-type(7) {
      order: 9;
    }

    .doubleSmallImages:nth-of-type(8) {
      order: 10;
    }

    `,
  '9': css`
  .paragraph:not(:last-child) {
    order: 1;
  }

  .paragraph ~ .paragraph:not(:last-child) {
    order: 5;
  }

  .paragraph {
    order:11;
    width: 100%;
  }

  .doubleHeightImages:first-of-type {
    order: 2;
  }

  .doubleHeightImages:nth-of-type(2) {
    order: 3;
  }

  .widerFullImage:nth-of-type(3) {
    order: 4;
  }

  .smallHeightLeftImage:nth-of-type(4) {
    order: 6;
  }

  .hugeImage:nth-of-type(5) {
    order: 7;
  }

  .doubleHeightImages:nth-of-type(6) {
    order: 8;
  }

  .doubleHeightImages:nth-of-type(7) {
    order: 9;
  }

  .widerFullImage:nth-of-type(8) {
    order: 10;
  }

  `,
  '10': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 6;
   }

   .paragraph {
     order:10;
     width: 100%;
   }

   .doubleHeightImages:first-of-type {
     order: 2;
   }

   .doubleHeightImages:nth-of-type(2) {
     order: 3;
   }

   .smallHeightLeftImage:nth-of-type(3) {
     order: 4;
   }

   .widerFullImage:nth-of-type(4) {
     order: 5;
   }

   .doubleHeightImages:nth-of-type(5) {
     order: 7;
   }

   .doubleHeightImages:nth-of-type(6) {
     order: 8;
   }

   .fullImage:nth-of-type(7) {
     order: 9;
   }

   `,
  '11': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 8;
   }

   .paragraph {
     order:11;
     width: 100%;
   }

   .doubleHeightImages:first-of-type {
     order: 2;
   }

   .doubleHeightImages:nth-of-type(2) {
     order: 3;
   }

   .smallHeightRightImage:nth-of-type(3) {
     order: 4;
   }

   .hugeImage:nth-of-type(4) {
     order: 5;
   }

   .smallHeightLeftImage:nth-of-type(5) {
     order: 6;
   }

   .widerFullImage:nth-of-type(6) {
     order: 7;
   }

   .hugeImage:nth-of-type(7) {
     order: 9;
   }
   .widerFullImage:nth-of-type(8) {
     order: 10;
   }

   `,
  '12': css`
  .paragraph:not(:last-child) {
    order: 1;
  }

  .paragraph ~ .paragraph:not(:last-child) {
    order: 7;
  }

  .paragraph {
    order:11;
    width: 100%;
  }

  .hugeImage:first-of-type {
    order: 2;
  }

  .smallHeightRightImage:nth-of-type(2) {
    order: 3;
  }

  .doubleHeightImages:nth-of-type(3) {
    order: 4;
  }

  .doubleHeightImages:nth-of-type(4) {
    order: 5;
  }

  .widerFullImage:nth-of-type(5) {
    order: 6;
  }

  .doubleHeightImages:nth-of-type(6) {
    order: 8;
  }

  .doubleHeightImages:nth-of-type(7) {
    order: 9;
  }

  .hugeImage:nth-of-type(8) {
    order: 10;
  }

  `,
  '13': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 5;
   }

   .paragraph {
     order:10;
     width: 100%;
   }

   .doubleHeightImages:first-of-type {
     order: 2;
   }

   .doubleHeightImages:nth-of-type(2) {
     order: 3;
   }

   .widerFullImage:nth-of-type(3) {
     order: 4;
   }

   .hugeImage:nth-of-type(4) {
     order: 6;
   }

   .smallHeightLeftImage:nth-of-type(5) {
     order: 7;
   }

   .doubleHeightImages:nth-of-type(6) {
     order: 8;
   }

   .doubleHeightImages:nth-of-type(7) {
     order: 9;
   }


   `,
  '14': css`
    .paragraph:not(:last-child) {
      order: 1;
    }

    .paragraph ~ .paragraph:not(:last-child) {
      order: 5;
    }

    .paragraph {
      order:10;
      width: 100%;
    }

    .hugeImage:first-of-type {
      order: 2;
    }

    .doubleHeightImages:nth-of-type(2) {
      order: 3;
    }

    .doubleHeightImages:nth-of-type(3) {
      order: 4;
    }

    .smallHeightLeftImage:nth-of-type(4) {
      order: 6;
    }

    .widerFullImage:nth-of-type(5) {
      order: 7;
    }

    .smallHeightRightImage:nth-of-type(6) {
      order: 8;
    }

    .hugeImage:nth-of-type(7) {
      order: 9;
    }


    `,
  '15': css`
  .paragraph:not(:last-child) {
    order: 1;
  }

  .paragraph ~ .paragraph:not(:last-child) {
    order: 6;
  }

  .paragraph {
    order:11;
    width: 100%;
  }

  .hugeImage:first-of-type {
    order: 2;
  }

  .smallHeightLeftImage:nth-of-type(2) {
    order: 3;
  }

  .widerFullImage:nth-of-type(3) {
    order: 4;
  }

  .hugeImage:nth-of-type(4) {
    order: 5;
  }

  .doubleHeightImages:nth-of-type(5) {
    order: 7;
  }

  .doubleHeightImages:nth-of-type(6) {
    order: 8;
  }

  .doubleHeightImages:nth-of-type(7) {
    order: 9;
  }

  .doubleHeightImages:nth-of-type(8) {
    order: 10;
  }

  `,
  '16': css`
   .paragraph:not(:last-child) {
     order: 1;
   }

   .paragraph ~ .paragraph:not(:last-child) {
     order: 5;
   }

   .paragraph {
     order:11;
     width: 100%;
   }

   .hugeImage:first-of-type {
     order: 2;
   }

   .smallHeightRightImage:nth-of-type(2) {
     order: 3;
   }

   .widerFullImage:nth-of-type(3) {
     order: 4;
   }

   .doubleHeightImages:nth-of-type(4) {
     order: 6;
   }

   .doubleHeightImages:nth-of-type(5) {
     order: 7;
   }

   .widerFullImage:nth-of-type(6) {
     order: 8;
   }

   .doubleHeightImages:nth-of-type(7) {
     order: 9;
   }

   .doubleHeightImages:nth-of-type(8) {
     order: 10;
   }


   `
}

export const ContentSection = styled.section<any>`
  .paragraph:not(:last-child) {
    width: ${vw(348, 'mobile')};
    margin: ${vw(30, 'mobile')} 0 ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')} {
      width: ${vw(1004, 'desktop')};
      margin: ${vw(30, 'desktop')} 0 ${vw(50, 'desktop')};
    }

  }
  .paragraph ~ .paragraph:not(:last-child) {
    margin: ${vw(14.5, 'mobile')} 0;
    padding: ${vw(8.5, 'mobile')} 0;
    width: ${vw(286, 'mobile')};

    ${mq.greaterThan('tablet')} {
      margin: ${vw(50, 'desktop')} 0;
      padding: ${vw(30, 'desktop')} 0;
      width: ${vw(993, 'desktop')};
    }
  }
  .paragraph:last-child {
    width: 100%;
    margin: ${vw(30, 'mobile')} 0;

    ${mq.greaterThan('tablet')} {
      width:${vw(960, 'desktop')};
      margin: ${vw(30, 'desktop')} 0 ${vw(50, 'desktop')};
    }
  }

  .slider, .video {
    margin-bottom: ${vw(15, 'mobile')};
    padding: ${vw(16, 'mobile')} 0;
    width: 100%;
    height: 100%;

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(50, 'desktop')};
      padding: ${vw(30, 'desktop')} 0;
    }
  }

  .doubleHeightImages {
    figure, .content, .video {
      height: ${vw(264, 'mobile')};
      width: auto;

      ${mq.greaterThan('tablet')} {
        height: ${vw(711, 'desktop')};
      }
    }
  }

  .doubleSmallImages {
    figure, .content, .video {
      height: ${vw(282, 'mobile')};
      width: auto;

      ${mq.greaterThan('tablet')} {
        height: ${vw(387, 'desktop')};
      }
    }
  }

  .widerFullImage,
  .fullImage,
  .hugeImage {
    width: 100%;
  }

  .widerFullImage {
    figure, .content, .video {
      height: ${vw(185, 'mobile')};
      width: auto

      ${mq.greaterThan('tablet')} {
        height: ${vw(566, 'desktop')};
      }
    }
  }

  .fullImage {
    figure, .content, .video {
      height: ${vw(265, 'mobile')};
      width: auto

      ${mq.greaterThan('tablet')} {
        height: ${vw(711, 'desktop')};
      }
    }
  }

  .hugeImage {
    figure, .content, .video {
      height: ${vw(282, 'mobile')};
      width: auto;

      ${mq.greaterThan('tablet')} {
        height: ${vw(819, 'desktop')};
      }
    }
  }

  .bigHeightLeftImage {
    figure, .content, .video {
      height: ${vw(220, 'mobile')};
      width: auto;

      ${mq.greaterThan('tablet')} {
        height: ${vw(800, 'desktop')}
      }
    }
  }

  .doubleSmallImagesVariant,
  .smallHeightLeftImage,
  .smallHeightRightImage {
    figure, .content, .video {
      height: ${vw(202, 'mobile')};

      ${mq.greaterThan('tablet')} {
        height: ${vw(373, 'desktop')};
      }
    }
  }

  .smallHeightLeftImage, .bigHeightLeftImage {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .smallHeightRightImage {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-left: calc(50% +  ${vw(7, 'mobile')});
    ${mq.greaterThan('tablet')} {
      padding-left: calc(50% +  ${vw(25, 'desktop')});
    }
  }


  ${({ $templateNumber }) => template[`${$templateNumber}`]}
`

export const ContentArticle = styled(motion.article)`
  &.doubleHeightImages,
  &.doubleSmallImages,
  &.smallHeightLeftImage,
  &.doubleSmallImagesVariant,
  &.bigHeightLeftImage {
    width: 50%;
  }

  &.doubleHeightImages.left,
  &.doubleSmallImages.left,
  &.doubleSmallImagesVariant.left {
    padding-left: ${vw(7, 'mobile')};

    ${mq.greaterThan('tablet')} {
      padding-left: ${vw(25, 'desktop')};
    }
  }
  &.doubleHeightImages.right,
  &.doubleSmallImages.right,
  &.bigHeightLeftImage,
  &.smallHeightLeftImage,
  &.doubleSmallImagesVariant.right {
    padding-right: ${vw(7, 'mobile')};

    ${mq.greaterThan('tablet')} {
      padding-right: ${vw(25, 'desktop')};
    }
  }
`

export const TextContent = styled(motion.div)<any>`
`
