'use client'
import { ReactNode, memo, useMemo, useState } from 'react'
import { useParams } from 'next/navigation'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useDropdown } from './useDropdown'
import { Figure } from '..'
import { getP15_2 , getTransition, mq, vw, hideScrollbar  } from '../../styles'

const DropdownWrapper = styled.div<any>`
  position: relative;
  right: 0;

  ${mq.lessThan('nexus7')} {
    width: ${vw(39, 'mobile')};
  }

  ${mq.greaterThan('nexus7')} {
    width: ${vw(39, 'mobile')};
  }

  ${mq.greaterThan('tablet')} {
     width: ${vw(39, 'desktop')};
  }

  ${mq.greaterThan('container')} {
      width: 39px;
  }

  .triggerButton {
    ${getP15_2()}
    align-items: center;
    color: var(--color-white);
    display: flex;
    flex-direction: row;
    padding: 0;

    span {
      color: var(--color-white);
      position: relative;

      ${mq.lessThan('nexus7')} {
        width: ${vw(18, 'mobile')};
      }

      ${mq.greaterThan('nexus7')} {
        width: ${vw(18, 'mobile')};
      }
      ${mq.greaterThan('tablet')} {
        width: ${vw(18, 'desktop')};
      }

      ${mq.greaterThan('container')} {
        width: 18px;
      }
    }

    figure  {
      transform: ${({ $expanded, $direction }) => $expanded && $direction === 'down' ? 'rotate(-180deg)' : $direction === 'up' && !$expanded ? 'rotate(180deg)':  'none'};

      ${mq.lessThan('nexus7')} {
        height: ${vw(15, 'mobile')};
        margin: 0 0 0 ${vw(5, 'mobile')};
        width:${vw(15, 'mobile')};
      }

       ${mq.greaterThan('nexus7')} {
        height: ${vw(15, 'mobile')};
        margin: 0 0 0 ${vw(5, 'mobile')};
        width:${vw(15, 'mobile')};
      }

      ${mq.greaterThan('tablet')} {
        margin: 0 0 0 ${vw(5, 'desktop')};
        height: ${vw(15, 'desktop')};
        width: ${vw(15, 'desktop')};
      }


      ${mq.greaterThan('container')} {
        margin: 0 0 0 5px;
        height: 15px;
        width: 15px;
      }
    }
  }

  ul {
    bottom: ${({ $direction }) => $direction === 'up' ? '200%':  'unset'};
    top: ${({ $direction }) => $direction === 'down' ? '160%':  'unset'};
  }
`

const OptionsList = styled(motion.ul)<any>`
  height: 0;
  opacity: 0;
  overflow-y: auto;
  position: absolute;
  width: auto;
  z-index: 10;
  ${hideScrollbar()}

  li {
    color: var(--color-white);
    cursor: pointer;
    opacity: .4;
    width: 100%;
    transition: opacity 300ms ease-in-out, background-color 300ms ease-in-out;
    ${getP15_2()}

    ${mq.lessThan('nexus7')} {
      margin-bottom: ${vw(15, 'mobile')};
    }

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(15, 'mobile')};
    }

    ${mq.greaterThan('tablet')} {
      margin-bottom: ${vw(10, 'desktop')};
    }

    ${mq.greaterThan('container')} {
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:active,
    &:hover,
    &.active {
      opacity: 1;
    }

    button {
      ${getP15_2()}
      color: var(--color-white);
    }
  }
`

const dropDrownIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--dropdown-arrow.svg',
  alt: 'dropdown icon',
  size: { width: 11, height: 5 }
}

const optionsListVariants = {
  collapsed: {
    height: '0',
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition: { ...getTransition(.8) }
  },
  expanded: {
    height: 'auto',
    opacity: 1,
    display: 'block',
    transition: { ...getTransition(.8) }
  }
}

interface IDropdown {
  className?: string,
  trigger: ReactNode | string,
  options: any,
  direction: string
}

const getOptions = (options, onClickOption, currentSelection) => {
  return options.map((option, idx) => (
    <li key={`${idx}key`} className={option.value === currentSelection?.toUpperCase() ? 'active' : ''}>
      <a href={`${option.path}`} onClick={onClickOption} >{option.value}</a>
    </li>
  ))
}

export const Dropdown = memo<IDropdown>(({ className = '', trigger, options, direction }) => {
  const [triggerRef, dropdownRef, expanded, toggleExpanded] = useDropdown()
  const { locale } = useParams()
  const [currentSelection, setSelection] = useState((typeof locale === 'string' ? locale.split('-')[0] : 'en').toUpperCase())

  const onClickOption = (option) => {
    toggleExpanded()
    setSelection(option.value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Options = useMemo(() => getOptions(options, onClickOption, currentSelection), [options, currentSelection])

  return (
    <DropdownWrapper className={className} $expanded={expanded} $direction={direction}>
      <button className='triggerButton' type='button' ref={triggerRef}  onClick={toggleExpanded}>
        <span>{trigger}</span>
        <Figure media={dropDrownIcon} />
      </button>
      <OptionsList ref={dropdownRef} initial='collapsed' variants={optionsListVariants} animate={expanded ? 'expanded' : 'collapsed'}>
        {Options}
      </OptionsList>
    </DropdownWrapper>
  )
})
