'use client'
import { memo, useMemo } from 'react'
import { Content } from './Content'
import { ContentSection } from './contentStyles'
import { randomNumberInRange, templates, shuffleArray } from '@/utils'
import { useDeviceType } from '@/hooks'

interface ISectionContents {
  images: any,
  paragraphs:[]
}

let firstDouble = true
let left = true


const isDoubleImage = {
  'doubleHeightImages': true,
  'doubleSmallImages': true,
  'doubleHeightImagesVariant': true,
  'doubleSmallImagesVariant': true
}



const getContentsByColumns = (templates, images, text) => templates.map((item, idx) => {
  if(item.type === 'text') item.titleText = text[item.position]
  else if (item.type === 'slider') item.media = shuffleArray(images, 8)
  if(isDoubleImage[item.value] && firstDouble) {
    firstDouble = false
    left = false
  } else {
    firstDouble = true
    left = true
  }

  return (
    <Content key={`${idx}-${item.type}-${item.value}`} content={item} className={`${isDoubleImage ? left ? 'left' : 'right' : ''} ${item.value}`} contentIndex={idx} />
  )
})


const mobileRange = randomNumberInRange(9, 16)
const desktopRange = randomNumberInRange(1, 8)

export const SectionContents = memo(({ images, paragraphs }: ISectionContents) => {
  const { isMobile } = useDeviceType()
  // const isMounted = useMount()
  // mobile templates from 9 to 16
  // desktop templates from 1 to 8
  const template = isMobile ? mobileRange : desktopRange

  const randomIndexParagraphs = randomNumberInRange(0, (paragraphs.length -1))
  const orderedRandomText = paragraphs[randomIndexParagraphs]
  const { top, middle, bottom } = orderedRandomText
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const text = [top, middle, bottom]
  // const shuffledArray = shuffleArray(images)
  const ContentsByColumns = useMemo(() => getContentsByColumns(templates[`template${template}`], images, text), [images, template])

  return  (
    <ContentSection $templateNumber={template}>
      {ContentsByColumns}
    </ContentSection>
  )
})
