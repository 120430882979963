'use client'
import { useMemo } from 'react'
import { ContentImage, ContentText, ContentVideo, ContentSlider } from './content-types'
import { ContentArticle } from './contentStyles'

const ContentsTypes = {
  'video': (data) => (<ContentVideo data={data} isContent={true} />),
  'text': (data) => (<ContentText data={data} />),
  'image': (data, contentIndex) => (<ContentImage data={data} contentIndex={contentIndex}/>),
  'slider': (data, contentIndex) => (<ContentSlider data={data} contentIndex={contentIndex}/>),
  'empty': () => (<div className='empty'></div>),
  'spacer': () => (<div className='spacer'></div>)
}

const getContentByType = (content, contentIndex) => {
  const { type } = content || {}
  if (!content || !type || !ContentsTypes[type]) return <></>

  return (ContentsTypes[type](content, contentIndex))
}

const variants = {
  initial: {
    opacity:1
  },
  show: {
    opacity: 1,
    transition: {
      duration: .2
    }
  },
  hide: {
    opacity: 0,
    transition: {
      duration: .8
    }
  }
}

export const Content = ({ content, className, contentIndex }) => {
  const ContentByType = useMemo(() => getContentByType(content, contentIndex), [content, contentIndex])

  return (
    <ContentArticle className={className + ' ' + content.type} variants={variants} animate={'show'} initial={ content?.type === 'text' ? 'initial':'hide' }>
      {ContentByType}
    </ContentArticle>
  )
}
