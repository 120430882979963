'use client'
import { memo } from 'react'
import type { ReactNode } from 'react'
import styled from 'styled-components'
import { mq, vw, designGrid } from '@/styles'

interface IHeaderContents {
  children: ReactNode
}

export const HeaderContents = memo<IHeaderContents>(({ children }) => {
  return (
    <Header>
      {children}
    </Header>
  )
})

const Header = styled.header`
  ${designGrid({})}
  align-content: flex-end;
  align-items: center;
  padding-top: ${vw(60, 'mobile')};

  ${mq.greaterThan('tablet')} {
    padding-top: ${vw(120, 'desktop')};
  }

  ${mq.greaterThan('desktop')} {
    padding-top: 120px;
  }

  h1, p {
    grid-column: 1 / span 6;
    text-align: center;
    width: 100%;
  }

  h1 {
    color: var(--color-black);
    font-weight: 400;
    margin-bottom: ${vw(30, 'mobile')};
    padding-top: ${vw(50, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(30, 'nexus7')};
      padding-top: ${vw(50, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 3 / span 8;
      margin-bottom: ${vw(30, 'desktop')};
      padding-top: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 30px;
      padding-top: 60px;
    }
  }

  p {
    color: var(--color-darkgreen);
    margin-bottom: ${vw(50, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      margin-bottom: ${vw(50, 'nexus7')};
    }

    ${mq.greaterThan('tablet')} {
      grid-column: 2 / span 10;
      margin-bottom: ${vw(60, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      margin-bottom: 60px;
    }
  }
`

